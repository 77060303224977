import { combineReducers } from "@reduxjs/toolkit"
import bookingReducer from "./bookingReducer"
import toastifyReducer from "./toastifyReducer"
import multipleBookingReducer from "./multipleBookingReducer"
import offerReducer from "./offerReducer"

const rootReducer = combineReducers({
  bookingReducer,
  toastifyReducer,
  multipleBookingReducer,
  offerReducer
})
export default rootReducer

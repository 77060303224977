import { REDUX_OFFER_DEFAULTS } from "../config"

const offerData = {
  data: {
    animation: false,
    description: ".",
    display: null,
    id: "",
    imgUrl:
      "https://res.cloudinary.com/djh8bzp9b/image/upload/v1731525326/uploads/image_216f985e11fd.jpg",
    name: ".",
    offerText: ".",
    textColor: "black",
  },
}

const offerReducer = (state = offerData, action) => {
  switch (action.type) {
    case REDUX_OFFER_DEFAULTS.GET_OFFER():
      return {
        ...state,
        data: action.payload,
      }

    default:
      return state
  }
}

export default offerReducer

import React, { useEffect, useState } from "react"
import logo from "../../asserts/boost-detailing-logo.png"
import Tostify from "../ToastifyNotify/Tostify"

const Navbar = () => {
  return (
    <>
      <Tostify />
      <div className="p-3 space-x-2 bg- black flex items-center justify-between md:justify-center rounded-b-lg shadow-md shadow-black">
        <div>
          <img src={logo} alt="logo" className="w-14" />
        </div>
        <div className="">
          <a
            className="bg-yellow-medium_dark text-sm py-2 px-2 rounded hover:shadow-md hover:shadow-white"
            href="https://boostceramic.ca/"
            target="_blank"
            rel="noreferrer"
          >
            Boost Ceramic
          </a>
        </div>
        <div className="">
          <a
            className="bg-yellow-medium_dark text-sm py-2 px-2 rounded hover:shadow-md hover:shadow-white"
            href="https://boostmobiledetailing.ca/"
            target="_blank"
            rel="noreferrer"
          >
            Boost Detailing
          </a>
        </div>
      </div>
    </>
  )
}

export default Navbar

import React, { useEffect, useState } from "react"
import moment from "moment"
import _ from "lodash"
import { useDispatch, useSelector } from "react-redux"
import { bookingAction } from "../../store/actions/bookingAction"
import { openToastify } from "../../store/actions/toastifyAction"
import {
  REDUX_STORE_DEFAULTS,
  REDUX_TOASTIFY_DEFAULTS,
} from "../../store/config"
import { AppointmentData } from "../../config/bookingDataConfig"
import { uploadImage } from "../../services/api/apiModule"
import upload from "../../asserts/upload.gif"
import {
  updateData,
  updatePriceData,
} from "../../store/actions/multipleBookingAction"
import GoogleMap from "../google_map/GoogleMap"
import Confirmation from "./Confirmation"

const InformationForm = () => {
  const dispatch = useDispatch()

  let {
    bookingStepsLabel,
    stepNo,
    location,
    staffCategory,
    service,
    addOnService,
    bookingSlot,
    userInfo,
    otherVehicleInfo,
  } = useSelector((state) => state.bookingReducer)
  let { allBookingData } = useSelector((state) => state.multipleBookingReducer)
  const [isUserInfo, setIsUserInfo] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    // address: "",
  })
  const [isadditionalInfo, setIsadditionalInfo] = useState({})
  const [isVehicleInfo, setIsVehicleInfo] = useState(otherVehicleInfo ?? {})
  const [isFilled, setIsFilled] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const selectedLocation = AppointmentData[location]

  useEffect(() => {
    // console.log("isadditionalInfo", isadditionalInfo)
    // console.log("isVehicleInfo", isVehicleInfo)
    // console.log("isUserInfo", isUserInfo)
  }, [isadditionalInfo, isVehicleInfo, isUserInfo])

  // Add consent value, power supply value, water supply value
  const handleAdditionalInfo = (event) => {
    const { name, value } = event.target
    setIsadditionalInfo((prevInfo) => ({ ...prevInfo, [name]: value }))
  }

  // uploadFile function
  const uploadFile = async (e) => {
    setIsLoading(true)
    try {
      const response = await uploadImage(e)
      if (response.urls?.length > 0) {
        handleVehicleInfo({
          target: { name: "picture", fileUrls: response.urls },
        })
      }
      // console.log("Uploaded images response:", response)
    } catch (error) {
      console.error("Error uploading files:", error)
      console.error(error?.response?.data)
    }
    setIsLoading(false)
  }

  const handleImages = (event) => {
    const { name, files } = event.target
    // console.log(files)

    if (files.length > 0) {
      setTimeout(() => {
        uploadFile(files)
      }, 600)
    }
    // console.log(files)
  }

  //add vehicle info
  const handleVehicleInfo = (event) => {
    const { name, value, fileUrls } = event.target

    setIsVehicleInfo((prevInfo) => ({
      ...prevInfo,
      [name]: name === "picture" ? fileUrls : value, // Handle pictures separately
    }))
  }

  useEffect(() => {
    setIsFilled(false)
    setTimeout(() => {
      dispatch(
        bookingAction(REDUX_STORE_DEFAULTS.UPDATE_USER_INFO(), isUserInfo)
      )
    }, 300)
  }, [isUserInfo])

  useEffect(() => {
    setTimeout(() => {
      dispatch(
        bookingAction(
          REDUX_STORE_DEFAULTS.UPDATE_OTHER_USER_INFO(),
          isadditionalInfo
        )
      )
    }, 300)
  }, [isadditionalInfo])

  useEffect(() => {
    setIsFilled(false)
    setTimeout(() => {
      dispatch(
        bookingAction(
          REDUX_STORE_DEFAULTS.UPDATE_OTHER_VEHICLE_INFO(),
          isVehicleInfo
        )
      )
      dispatch(updatePriceData(isVehicleInfo))
    }, 300)
  }, [isVehicleInfo])

  const handleSubmit = (e) => {
    setIsFilled(false)
    e.preventDefault()

    // Handle form submission here
    // console.log(
    //   isadditionalInfo.powerSupply,
    //   isadditionalInfo.waterSupply,
    //   isUserInfo.address,
    //   userInfo.address,
    //   "h"
    // )

    if (location.id === 1) {
      if (!isadditionalInfo.powerSupply) {
        dispatch(
          openToastify(
            "Please provide information for power supply.",
            REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_WARN()
          )
        )
        return
      }

      if (!isadditionalInfo.waterSupply) {
        dispatch(
          openToastify(
            "Please provide information for water supply.",
            REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_WARN()
          )
        )
        return
      }

      if (!userInfo.address) {
        dispatch(
          openToastify(
            "Please select your address.",
            REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_WARN()
          )
        )
        return
      }
    }

    setIsFilled(true)
  }

  useEffect(() => {
    setIsFilled(false)
  }, [userInfo.address])

  useEffect(() => {
    if (isFilled) {
      // console.log("true", isFilled)
      handleAdditionalInfo({ target: { name: "conset", value: "I agree" } })
    } else {
      // console.log("false", isFilled)
      handleAdditionalInfo({ target: { name: "conset", value: "" } })
    }
  }, [isFilled])

  return (
    <>
      <Confirmation />
      <div className="sm:w-[90%] h-full accent-yellow-light ml-2">
        <h1>Fill the Informations:</h1>
        <div className="flex flex-col items-start justify-between h-full">
          <form
            onSubmit={handleSubmit}
            className="sm:space-y-0 space-y-5 sm:grid sm:grid-cols -2 gap-4 pt-4"
          >
            <div className="flex flex-col sm:col-span-2">
              <span className="text-sm">
                Enter Full Name: <span className="text-red-500">*</span>
              </span>
              <input
                type="text"
                className="h-10 rounded-md px-2 text-black border"
                placeholder="Enter Full Name"
                required
                value={isUserInfo.name}
                onChange={(e) =>
                  setIsUserInfo((name) => ({ ...name, name: e.target.value }))
                }
              />
            </div>
            <div className="flex flex-col">
              <span className="text-sm">
                Enter Email For Confirmation:
                <span className="text-red-500">*</span>
              </span>
              <input
                type="email"
                className="h-10 rounded-md px-2 text-black border"
                placeholder="Enter Email"
                required
                value={isUserInfo.email}
                onChange={(e) =>
                  setIsUserInfo((email) => ({
                    ...email,
                    email: e.target.value,
                  }))
                }
              />
            </div>
            <div className="flex flex-col">
              <span className="text-sm">
                Enter Phone Number: <span className="text-red-500">*</span>
              </span>
              <input
                type="number"
                className="h-10 rounded-md px-2 text-black border"
                placeholder="Ph. Number (+1 236-992-8610)"
                required
                value={isUserInfo.phoneNumber}
                onChange={(e) => {
                  let input = e.target.value
                  input = input.replace(/\D/g, "")
                  setIsUserInfo((phoneNumber) => ({
                    ...phoneNumber,
                    phoneNumber: input,
                  }))
                }}
                // Additional attributes to prevent invalid input
                onKeyDown={(e) => {
                  // Prevent input if the length is 10 and a number key is pressed
                  if (
                    isUserInfo.phoneNumber.length >= 10 &&
                    /[0-9]/.test(e.key)
                  ) {
                    e.preventDefault()
                  }
                }}
                onPaste={(e) => {
                  e.preventDefault()
                }}
              />
            </div>

            {location.id === 1 && (
              <>
                {/* address */}
                {/* <div className="flex flex-col col-span-2">
                  <span className="text-sm">
                    Enter Street Address:{" "}
                    <span className="text-red-500">*</span>
                  </span>
                  <textarea
                    className="h-10 w-[100%] rounded-md px-2 text-black py-2"
                    name="Address"
                    id=""
                    placeholder={"Enter Address: " + isUserInfo.address}
                    required
                    // value={isUserInfo.address}
                    onChange={(e) =>
                      setIsUserInfo((address) => ({
                        ...address,
                        address: e.target.value,
                      }))
                    }
                  ></textarea>
                </div>
                <div className="flex flex-col col-span-2">
                  <span className="text-sm">
                    Enter City: <span className="text-red-500">*</span>
                  </span>
                  <textarea
                    className="h-10 rounded-md px-2 text-black py-2"
                    name="Address"
                    id=""
                    placeholder={"Enter City: " + isUserInfo.city}
                    required
                    // value={isUserInfo.city}
                    onChange={(e) =>
                      setIsUserInfo((city) => ({
                        ...city,
                        city: e.target.value,
                      }))
                    }
                  ></textarea>
                </div> */}
                <div className="flex flex-col col-span-2">
                  <span className="text-sm">
                    Enter & Select Address:{" "}
                    <span className="text-red-500">*</span>
                  </span>
                  <GoogleMap />
                </div>
              </>
            )}

            <span className="text-sm col-span-2">
              Please provide the information below. We will contact you with a
              pricing estimate within 24 hours.
              <hr />
            </span>
            {allBookingData.map((booking, index) => {
              const { vehicle } = booking
              return (
                <>
                  {/* Commercial Inputs */}
                  {vehicle.id === 4 && (
                    <>
                      <div className="flex flex-col">
                        <span className="text-sm">
                          Upload Pictures of Commercial Vehicle
                          <span className="text-red-500">*</span>
                        </span>
                        <input
                          type="file"
                          name="picture"
                          className="h-10 rounded-md px-2 text- black"
                          multiple
                          accept="image/jpeg,image/png,image/jpg"
                          required
                          onChange={handleImages}
                          disabled={isLoading}
                        />
                      </div>
                    </>
                  )}

                  {/* RV's Motorhomes Input */}
                  {vehicle.id === 5 && (
                    <>
                      <div className="flex flex-col">
                        <span className="text-sm">
                          Year of RV: <span className="text-red-500">*</span>
                        </span>
                        <input
                          type="number"
                          name="rvYear"
                          className="h-10 rounded-md px-2 text-black border"
                          placeholder="Year of RV"
                          required
                          value={isVehicleInfo.rvYear}
                          onChange={handleVehicleInfo}
                        />
                      </div>
                      <div className="flex flex-col">
                        <span className="text-sm">
                          Make & Model of RV:{" "}
                          <span className="text-red-500">*</span>
                        </span>
                        <input
                          type="text"
                          name="rvMakeModel"
                          className="h-10 rounded-md px-2 text-black border"
                          placeholder="Make & Model of RV"
                          required
                          onChange={handleVehicleInfo}
                        />
                      </div>
                      {/* <div className="flex flex-col">
                        <span className="text-sm">
                          RV Category: <span className="text-red-500">*</span>
                        </span>
                        <input
                          type="text"
                          name="rvCategory"
                          className="h-10 rounded-md px-2 text-black border"
                          placeholder="RV Category"
                          required
                          onChange={handleVehicleInfo}
                        />
                      </div> */}
                      {/* <div className="flex flex-col">
                        <span className="text-sm">
                          RV Length In Feet:{" "}
                          <span className="text-red-500">*</span>
                        </span>
                        <input
                          type="number"
                          name="rvLength"
                          className="h-10 rounded-md px-2 text-black border"
                          placeholder="RV Length In Feet"
                          required
                          onChange={handleVehicleInfo}
                          min={1}
                        />
                      </div> */}
                      <div className="flex flex-col col-span-2">
                        <span className="text-sm">
                          Additional Information For RV
                        </span>
                        <span className="text-xs">(Please Elaborate)</span>
                        <textarea
                          name="rvAdditonalInfo"
                          className="h-20 rounded-md px-2 text-black border"
                          placeholder="Additional Information"
                          onChange={handleVehicleInfo}
                        ></textarea>
                      </div>
                      <div className="flex flex-col">
                        <span className="text-sm">
                          Pictures of RV / Motorhome
                          <span className="text-red-500">*</span>
                        </span>
                        <input
                          type="file"
                          name="picture"
                          className="h-10 rounded-md px-2 text- black"
                          multiple
                          accept="image/jpeg,image/png,image/jpg"
                          required
                          onChange={handleImages}
                          disabled={isLoading}
                        />
                        {/* <div>
                    {file.length > 0 && (
                      <ul>
                        {file.map((file, index) => (
                          <li key={index}>{file.name}</li>
                        ))}
                      </ul>
                    )}
                  </div> */}
                      </div>
                    </>
                  )}

                  {/* FireTruck Input */}
                  {vehicle.id === 6 && (
                    <>
                      <div className="flex flex-col">
                        <span className="text-sm">
                          Pictures of Fire Truck
                          <span className="text-red-500">*</span>
                        </span>
                        <input
                          type="file"
                          name="picture"
                          className="h-10 rounded-md px-2 text- black"
                          multiple
                          accept="image/jpeg,image/png,image/jpg"
                          required
                          onChange={handleImages}
                          disabled={isLoading}
                        />
                      </div>
                    </>
                  )}

                  {/* Passenger Bus Inputs */}
                  {vehicle.id === 7 && (
                    <>
                      <div className="flex flex-col">
                        <span className="text-sm">
                          Model<span className="text-red-500">*</span>
                        </span>
                        <input
                          type="text"
                          name="busModel"
                          className="h-10 rounded-md px-2 text-black border"
                          placeholder="Model"
                          required
                          onChange={handleVehicleInfo}
                        />
                      </div>
                      {/* <div className="flex flex-col">
                        <span className="text-sm">
                          Number of Seats?
                          <span className="text-red-500">*</span>
                        </span>
                        <input
                          type="number"
                          name="busSeats"
                          className="h-10 rounded-md px-2 text-black border"
                          placeholder="Number of Seats"
                          required
                          onChange={handleVehicleInfo}
                          min={1}
                        />
                      </div> */}
                      <div className="flex flex-col">
                        <span className="text-sm">
                          Pictures of Passenger Bus
                          <span className="text-red-500">*</span>
                        </span>
                        <input
                          type="file"
                          name="picture"
                          className="h-10 rounded-md px-2 text- black"
                          multiple
                          accept="image/jpeg,image/png,image/jpg"
                          required
                          onChange={handleImages}
                          disabled={isLoading}
                        />
                      </div>
                    </>
                  )}

                  {/* Boat Inputs */}
                  {vehicle.id === 8 && (
                    <>
                      <div className="flex flex-col col-span-2">
                        <span className="text-sm">
                          Year of Boat: <span className="text-red-500">*</span>
                        </span>
                        <input
                          type="number"
                          name="boatYear"
                          className="h-10 rounded-md px-2 text-black border"
                          placeholder="Year of Boat"
                          required
                          onChange={handleVehicleInfo}
                        />
                      </div>
                      <div className="flex flex-col">
                        <span className="text-sm">
                          Make & Model of Boat:{" "}
                          <span className="text-red-500">*</span>
                        </span>
                        <input
                          type="text"
                          name="boatMakeModel"
                          className="h-10 rounded-md px-2 text-black border"
                          placeholder="Make & Model of Boat"
                          required
                          onChange={handleVehicleInfo}
                        />
                      </div>
                      {/* <div className="flex flex-col">
                        <span className="text-sm">
                          Boat Length In Feet:{" "}
                          <span className="text-red-500">*</span>
                        </span>
                        <input
                          type="number"
                          name="boatLength"
                          className="h-10 rounded-md px-2 text-black border"
                          placeholder="Boat Length In Feet"
                          required
                          onChange={handleVehicleInfo}
                          min={1}
                        />
                      </div> */}
                      <div className="flex flex-col">
                        <span className="text-sm">
                          Pictures of Boat
                          <span className="text-red-500">*</span>
                        </span>
                        <input
                          type="file"
                          name="picture"
                          className="h-10 rounded-md px-2 text- black"
                          multiple
                          accept="image/jpeg,image/png,image/jpg"
                          required
                          onChange={handleImages}
                          disabled={isLoading}
                        />
                      </div>
                    </>
                  )}

                  {/* House Inputs */}
                  {vehicle.id === 10 && (
                    <>
                      <div className="flex flex-col">
                        <span className="text-sm">
                          Number of Stories:{" "}
                          <span className="text-red-500">*</span>
                        </span>
                        <input
                          type="number"
                          name="houseStory"
                          className="h-10 rounded-md px-2 text-black border"
                          placeholder="Number of Stories of House"
                          required
                          onChange={handleVehicleInfo}
                          min={1}
                        />
                      </div>
                      {/* <div className="flex flex-col">
                        <span className="text-sm">
                          Total Sqft <span className="text-red-500">*</span>
                        </span>
                        <input
                          type="number"
                          name="houseSqFeet"
                          className="h-10 rounded-md px-2 text-black border"
                          placeholder="Total Square feet of House"
                          required
                          onChange={handleVehicleInfo}
                          min={1}
                        />
                      </div> */}
                    </>
                  )}
                </>
              )
            })}

            {isLoading && (
              <div className="flex justify-center items-center space-x-3">
                <img src={upload} alt="" className="w-10 h-10 object-cover" />
                <span>Uploading...</span>
              </div>
            )}

            {/* extra info on mobile location */}
            {/* {location.id === 1 && (
              <>
                <div className="flex flex-col items-start space-y-1 col-span-2 text-sm">
                  <div>
                    <p>
                      Access to Power Supply?{" "}
                      <span className="text-red-500">*</span>
                    </p>
                  </div>
                  <div
                    className={` space-x-1 ${
                      isadditionalInfo.powerSupply === "no" ? "opacity-70" : ""
                    } `}
                  >
                    <input
                      className="cursor-pointer"
                      type="radio"
                      id="powerYes"
                      name="powerSupply"
                      value="yes"
                      required
                      checked={isadditionalInfo.powerSupply === "yes"}
                      onChange={handleAdditionalInfo}
                    />
                    <label htmlFor="powerYes" className="cursor-pointer">
                      Yes, Access To Power Supply
                    </label>
                  </div>
                  <div
                    className={` space-x-1 ${
                      isadditionalInfo.powerSupply === "yes" ? "opacity-70" : ""
                    } `}
                  >
                    <input
                      className="cursor-pointer"
                      type="radio"
                      id="powerNo"
                      name="powerSupply"
                      value="no"
                      required
                      checked={isadditionalInfo.powerSupply === "no"}
                      onChange={handleAdditionalInfo}
                    />
                    <label htmlFor="powerNo" className="cursor-pointer">
                      No (if No, $15 Generator Fees Apply)
                    </label>
                  </div>
                </div>

                <div className="flex flex-col items-start space-y-1 col-span-2 text-sm">
                  <div>
                    <p>
                      Access to Water? <span className="text-red-500">*</span>
                    </p>
                  </div>
                  <div
                    className={` space-x-1 ${
                      isadditionalInfo.waterSupply === "no" ? "opacity-70" : ""
                    } `}
                  >
                    <input
                      className="cursor-pointer"
                      type="radio"
                      id="waterYes"
                      name="waterSupply"
                      value="yes"
                      required
                      checked={isadditionalInfo.waterSupply === "yes"}
                      onChange={handleAdditionalInfo}
                    />
                    <label htmlFor="waterYes" className="cursor-pointer">
                      Yes
                    </label>
                  </div>
                  <div
                    className={` space-x-1 ${
                      isadditionalInfo.waterSupply === "yes" ? "opacity-70" : ""
                    } `}
                  >
                    <input
                      className="cursor-pointer"
                      type="radio"
                      id="waterNo"
                      name="waterSupply"
                      value="no"
                      required
                      checked={isadditionalInfo.waterSupply === "no"}
                      onChange={handleAdditionalInfo}
                    />
                    <label htmlFor="waterNo" className="cursor-pointer">
                      No
                    </label>
                  </div>
                </div>
              </>
            )} */}

            {/* extra info on mobile location */}
            {location.id === 1 && (
              <>
                <div className="flex flex-col items-start space-y-1 col-span-2 text-sm">
                  <div>
                    <p>
                      Access to Power Supply?{" "}
                      <span className="text-red-500">*</span>
                    </p>
                  </div>
                  <div
                    className={`space-x-1 py-2 ${
                      isadditionalInfo.powerSupply === "no" ? "opacity-70" : ""
                    }`}
                  >
                    <span
                      className={`cursor-pointer py-2 px-5 border rounded ${
                        isadditionalInfo.powerSupply === "yes"
                          ? "bg-yellow-medium_dark text-white shadow shadow-yellow-light"
                          : "bg-white border-gray-300"
                      }`}
                      id="powerYes"
                      name="powerSupply"
                      value="yes"
                      onClick={() =>
                        handleAdditionalInfo({
                          target: { name: "powerSupply", value: "yes" },
                        })
                      }
                    >
                      Yes
                    </span>
                  </div>
                  <div
                    className={`space-x-1 py-2 ${
                      isadditionalInfo.powerSupply === "yes" ? "opacity-70" : ""
                    }`}
                  >
                    <span
                      className={`cursor-pointer py-2 px-5 border rounded ${
                        isadditionalInfo.powerSupply === "no"
                          ? "bg-yellow-medium_dark text-white shadow shadow-yellow-light"
                          : "bg-white border-gray-300"
                      }`}
                      id="powerNo"
                      name="powerSupply"
                      value="no"
                      onClick={() =>
                        handleAdditionalInfo({
                          target: { name: "powerSupply", value: "no" },
                        })
                      }
                    >
                      No
                    </span>
                    <span>(if No, $15 Generator Fees Apply)</span>
                  </div>
                </div>

                <div className="flex flex-col items-start space-y-1 col-span-2 text-sm">
                  <div>
                    <p>
                      Access to Water? <span className="text-red-500">*</span>
                    </p>
                  </div>
                  <div
                    className={`space-x-1 py-2 ${
                      isadditionalInfo.waterSupply === "no" ? "opacity-70" : ""
                    }`}
                  >
                    <span
                      className={`cursor-pointer py-2 px-5 border rounded ${
                        isadditionalInfo.waterSupply === "yes"
                          ? "bg-yellow-medium_dark text-white shadow shadow-yellow-light"
                          : "bg-white border-gray-300"
                      }`}
                      id="waterYes"
                      name="waterSupply"
                      value="yes"
                      onClick={() =>
                        handleAdditionalInfo({
                          target: { name: "waterSupply", value: "yes" },
                        })
                      }
                    >
                      Yes
                    </span>
                  </div>
                  <div
                    className={`space-x-1 py-2 ${
                      isadditionalInfo.waterSupply === "yes" ? "opacity-70" : ""
                    }`}
                  >
                    <span
                      className={`cursor-pointer py-2 px-5 border rounded ${
                        isadditionalInfo.waterSupply === "no"
                          ? "bg-yellow-medium_dark text-white shadow shadow-yellow-light"
                          : "bg-white border-gray-300"
                      }`}
                      id="waterNo"
                      name="waterSupply"
                      value="no"
                      onClick={() =>
                        handleAdditionalInfo({
                          target: { name: "waterSupply", value: "no" },
                        })
                      }
                    >
                      No
                    </span>
                  </div>
                </div>

                <div className="text-sm w-full col-span-2">
                  <p>Additional Details (Optional)</p>
                  <textarea
                    name="note"
                    id="note"
                    className="w-full border-2 p-2"
                    onChange={handleAdditionalInfo}
                  ></textarea>
                </div>
              </>
            )}

            {/* accept terms */}
            <div className="flex flex-col items-start space-y-1 col-span-2 text-sm">
              <div>
                <p>Consent For Essential Services :</p>
                <p>
                  If You Want to Cancel Your Appointment, Please Inform to us
                  Before 24hrs. Because We provide Mobile Service.{" "}
                  <span className="text-red-500">*</span>
                </p>
              </div>
              <div className="space-x-1">
                {/* <input
                  className="cursor-pointer"
                  type="radio"
                  id="consent"
                  name="consent"
                  value="agree"
                  checked={isadditionalInfo.consent === "agree"}
                  onChange={handleAdditionalInfo}
                />
                <label for="consent" className="cursor-pointer">
                  I agree
                </label> */}
                <button
                  type="submit"
                  disabled={isLoading}
                  className={` py-2 px-4 rounded font-medium border-2 ${
                    isFilled
                      ? "ring-yellow- light bg-yellow-medium_dark text-white"
                      : "ring- white bg-white text-black ring- white"
                  }  `}
                >
                  I agree
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default InformationForm
